import axios from "axios";
import React from "react";

import {
    NOTES_SHEET,
    META_SHEET,
    HEALTH_SHEET,
    EDUCATION_SHEET,
    COUNCILS_SHEET,
    ENERGY_SHEET,
    WATER_SHEET,
    SPORTS_SHEET,
    INFRASTRUCTURE_SHEET,
    LGA_SHEET,
    LGA_META_SHEET,
} from "../config/constants";

const useQueenslandData = () => {
    const [notesData, setNotesData] = React.useState(null);
    const [metaData, setMetaData] = React.useState(null);
    const [healthData, setHealthData] = React.useState(null);
    const [educationData, setEducationData] = React.useState(null);
    const [councilsData, setCouncilsData] = React.useState(null);
    const [energyData, setEnergyData] = React.useState(null);
    const [waterData, setWaterData] = React.useState(null);
    const [sportsData, setSportsData] = React.useState(null);
    const [infrastructureData, setInfrastructureData] = React.useState(null);
    const [lgaData, setLgaData] = React.useState(null);
    const [lgaDataMeta, setLgaDataMeta] = React.useState(null);

    React.useEffect(() => {
        Promise.all([
            axios(NOTES_SHEET)
                .then(res => res.data)
                .then(setNotesData),

            axios(META_SHEET)
                .then(res => res.data)
                .then(setMetaData),

            axios(HEALTH_SHEET)
                .then(res => res.data)
                .then(setHealthData),

            axios(EDUCATION_SHEET)
                .then(res => res.data)
                .then(setEducationData),

            axios(COUNCILS_SHEET)
                .then(res => res.data)
                .then(setCouncilsData),

            axios(ENERGY_SHEET)
                .then(res => res.data)
                .then(setEnergyData),

            axios(WATER_SHEET)
                .then(res => res.data)
                .then(setWaterData),

            axios(SPORTS_SHEET)
                .then(res => res.data)
                .then(setSportsData),

            axios(INFRASTRUCTURE_SHEET)
                .then(res => res.data)
                .then(setInfrastructureData),

            axios(LGA_SHEET)
                .then(res => res.data)
                .then(setLgaData),

            axios(LGA_META_SHEET)
                .then(res => res.data)
                .then(setLgaDataMeta),
        ]);
    }, []);

    return {
        notesData,
        metaData,
        healthData,
        educationData,
        councilsData,
        energyData,
        waterData,
        sportsData,
        infrastructureData,
        lgaData,
        lgaDataMeta,
    };
};

export default useQueenslandData;
