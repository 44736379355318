const initialState = {
	isFetchingCouncils: true,
	activeCouncil: null,
	activeDam: null,
	activePage: 'search',
	isMobile: false,
  };
  
  export default (state = initialState, action) => {
	switch (action.type) {
	  case 'REGIONS_ALL:REQUEST': {
		return {
		  ...state,
		  isFetchingCouncils: true,
		};
	  }
	  case 'REGIONS_ALL:SUCCESS': {
		return {
		  ...state,
		  isFetchingCouncils: false,
		};
	  }
	  case 'REGION:SET_ACTIVE': {
		return {
		  ...state,
		  activeCouncil: action.payload.id,
		};
	  }
	  case 'PAGE:SET_ACTIVE': {
		return {
		  ...state,
		  activePage: action.payload.name,
		};
	  }
	  case 'DAM:SET_ACTIVE': {
		return {
		  ...state,
		  activeDam: action.payload.name,
		};
	  }
	  case 'SET_IS_MOBILE': {
		return { ...state, isMobile: action.payload };
	  }
	  default: {
		return state;
	  }
	}
  };
  
  export const getIsFetchingCouncils = state => state.isFetchingCouncils;
  export const getActiveCouncil = state => state.activeCouncil;
  export const getActivePage = state => state.activePage;
  export const getIsMobile = state => state.isMobile;
  export const getActiveDam = state => state.activeDam;
  