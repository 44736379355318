import React from "react";
import styles from "./launch-screen.module.scss";

export default function LaunchScreen(props) {
    return (
        <div className={styles.launchScreenWrapper}>
            <h4>About this tool</h4>
            <p>
                This Queensland Audit Office interactive dashboard helps you understand more about the region you live
                in and the public services you receive. You can search by address, postcode, or region to explore how
                Queensland’s public sector and local government entities are performing.
            </p>
            <h4>Preview</h4>
            <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/img/launch-feature.jpg`}
                alt="screenshots of QAO dashboards"
            />
        </div>
    );
}
