export default (state = [], action) => {
	switch (action.type) {
	  	case 'REGIONS_QLD_GEO:SUCCESS': {
			console.log('succ')
			return {
				...state,
				qldCouncilsGeo: action.payload,
			};
	  	}
	  	case 'HHS_GEO:SUCCESS': {
			return {
				...state,
				hhsGeo: action.payload,
			}
		}
		case 'QAO_DISTRICTS_GEO:SUCCESS': {
			return {
				...state,
				qaoDistrictGeo: action.payload,
			}
		}
		case 'SA4S_GEO:SUCCESS': {
			return {
				...state,
				sa4Geo: action.payload,
			}
		}
		case 'SE_QLD_DISTRICTS_GEO:SUCCESS': {
			return {
				...state,
				seQldDistrictsGeo: action.payload,
			}
		}
		default: {
			return state;
		}
	}
  };

export const getQldCouncilsGeo = state => state.qldCouncilsGeo;
export const getHhsGeo = state => state.hhsGeo;
export const getQaoDistrictGeo = state => state.qaoDistrictGeo;
export const getSa4Geo = state => state.sa4Geo;
export const getSeQldDistrictsGeo = state => state.seQldDistrictsGeo;