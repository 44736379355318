export const sections = {
	Councils: {
		location_key: "region_short_name",
		factoids: [
			{
				column: "council_staff_fte",
				type: "general",
				name: "Council staff FTE",
				unit: "number",
				icon: "councils-council-staff-fte.svg",
			},
			{
				column: "financial_revenue",
				type: "financial",
				name: "Total revenue",
				unit: "currency",
			},
			{
				column: "financial_expenses",
				type: "financial",
				name: "Total expenses",
				unit: "currency",
			},
			{
				column: "financial_assets",
				type: "financial",
				name: "Total assets",
				unit: "currency",
			},
			{
				column: "financial_liabilities",
				type: "financial",
				name: "Total liabilities",
				unit: "currency",
			},
			{
				column: "financial_operating_surplus_ratio",
				type: "financial",
				name: "Operating surplus ratio",
				unit: "percentage",
			},
		],
	},
	Education: {
		location_key: "region",
		factoids: [
			{
				column: "schools",
				type: "general",
				name: "Schools",
				unit: "number",
				icon: "education-schools.svg",
			},
			{
				column: "universities_tafe_campuses",
				type: "general",
				name: "Universities and TAFE campuses",
				unit: "number",
				icon: "education-universities-tafe-campuses.svg",
			},
			{
				column: "internet_speed_per_student",
				type: "general",
				name: "Internet speed per student (Kbps)",
				unit: "number",
				icon: "education-internet-speed-per-student.svg",
			},
			{
				column: "school_staff_fte",
				type: "general",
				name: "School staff FTE",
				unit: "number",
				icon: "education-school-staff-fte.svg",
			},
			{
				column: "university_tafe_staff_fte",
				type: "general",
				name: "University and TAFE staff FTE",
				unit: "number",
				icon: "education-university-tafe-staff-fte.svg",
			},
			{
				column: "students",
				type: "general",
				name: "Students",
				unit: "number",
				icon: "education-students.svg",
			},
		],
	},
	Health: {
		location_key: "region",
		factoids: [
			{
				column: "hospitals",
				type: "general",
				name: "Hospitals",
				unit: "number",
				icon: "health-hospitals.svg",
			},
			{
				column: "hospital_staff_fte",
				type: "general",
				name: "Hospital staff FTE",
				unit: "number",
				icon: "health-hospital-staff-fte.svg",
			},
			{
				column: "hospitalisations",
				type: "general",
				name: "Hospitalisations",
				unit: "number",
				icon: "health-hospitalisations.svg",
			},
			{
				column: "financial_revenue",
				type: "financial",
				name: "Total revenue",
				unit: "currency",
			},
			{
				column: "financial_expenses",
				type: "financial",
				name: "Total expenses",
				unit: "currency",
			},
			{
				column: "financial_assets",
				type: "financial",
				name: "Total assets",
				unit: "currency",
			},
			{
				column: "financial_liabilities",
				type: "financial",
				name: "Total liabilities",
				unit: "currency",
			},
			{
				column: "financial_operating_result",
				type: "financial",
				name: "Operating result",
				unit: "currency",
			},
		],
	},
	Energy: {
		location_key: "region",
		factoids: [
			{
				column: "powerstations",
				type: "general",
				name: "Power stations",
				unit: "number",
				icon: "energy-powerstations.svg",
			},
			{
				column: "generation_capacity",
				type: "general",
				name: "Generation capacity",
				unit: "categorical",
				icon: "energy-generation-capacity.svg",
			},
			{
				column: "household_rooftop_solar",
				type: "general",
				name: "Household rooftop solar",
				unit: "percentage",
				icon: "rooftop-solar.svg",
			},
		],
	},
	Water: {
		location_key: "region_short_name",
		factoids: [
			{
				column: "dams",
				type: "general",
				name: "Dams",
				unit: "number",
				icon: "water-dams.svg",
			},
			{
				column: "drought_status",
				type: "general",
				name: "Drought status",
				unit: "categorical",
				icon: "water-drought-status.svg",
			},
		],
	},
	Sports: {
		location_key: "region",
		factoids: [
			{
				column: "grants",
				type: "general",
				name: "Grants",
				unit: "currency",
				icon: "sports-grants-by-region.svg",
			},
		],
	},
	Infrastructure: {
		location_key: "region",
		factoids: [
			{
				column: "spend_on_major_projects",
				type: "general",
				name: "Spend on major projects",
				unit: "currency",
				icon: "infrastructure-spend-on-major-projects.svg",
			},
		],
	},
};
