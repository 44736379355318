export const lgasGeoJson = 'data/qld_lga.geo.json';

export const hhsGeoJson = 'data/hhs.geo.json';
export const qaoDistrictGeoJson = 'data/qao-districts.geo.json';
export const sa4GeoJson = 'data/qld_sa4s.geo.json';
export const seQldDistrictsGeoJson = 'data/south-east-qld-districts.geo.json';

// neighbours.geo.json
// export const hhsDataFilePath = 'data/hhs-data.json';
// export const dataFilePath = 'data/water_data.json';
