import React from "react";
import ReactDOM from "react-dom";
import Embed from "./App"; // or where ever the root is
import { Provider } from "react-redux";
import store from "./store";

function getEl(embedSelector) {
    if (typeof embedSelector === "string") {
        const el = document.querySelector(embedSelector);
        // if (!el) throw new Error(`No div matching selector "${embedSelector}"`);
        if (!el) return;
        return el;
    } else {
        return embedSelector;
    }
}

window.addEventListener("DOMContentLoaded", () => {
    if (getEl("[data-queensland-dashboard-embed]")) {
        ReactDOM.render(
            <Provider store={store}>
                <Embed />
            </Provider>,
            getEl("[data-queensland-dashboard-embed]")
        );
    }
});
