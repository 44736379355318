import React from "react";
import styles from "./section.module.scss";
import { sections } from "../../config/sections";
import Select from "react-select";
import TextTransition, { presets } from "react-text-transition";
import { humanizeNumber } from "../../helpers/formatters";
import { CAVEATS } from "../../config/constants";

export default function Section(props) {
    const { location, regions, regionType, topic, meta } = props;

    const [selectedRegion, setSelectedRegion] = React.useState(null);
    const [allRegions, setAllRegions] = React.useState([]);
    const [comparison, setComparison] = React.useState(false);
    const [comparableRegions, setComparableRegions] = React.useState([]);
    const [comparedRegion, setComparedRegion] = React.useState(null);

    const handleComparedRegionSelect = selectedOption => {
        const r = regions.find(region => region && region[sections[topic].location_key] === selectedOption.value);
        setComparedRegion(r);
        setComparison(true);
    };

    React.useEffect(() => {
        if (regions) {
            setAllRegions(regions);
        }
    }, [regions]);

    React.useEffect(() => {
        if (location) {
            setSelectedRegion(regions.find(region => region && region[sections[topic].location_key] === location));
        }
    }, [location, regions, topic]);

    React.useEffect(() => {
        if (location && selectedRegion) {
            let region_names = regions
                .filter(r => r["region"] !== undefined && r["region"] !== selectedRegion["region"])
                .map(region => {
                    return {
                        topic: topic,
                        value: region[sections[topic].location_key],
                        label: region["region"],
                    };
                });
            region_names.unshift({
                topic: topic,
                value: "",
                label: "",
            });
            // console.log('region_names', region_names);
            setComparableRegions(region_names);
        }
    }, [location, selectedRegion, regions, topic]);

    const customStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "rgba(0,0,0,0.7)",
        }),
        option: (styles, { data }) => ({
            ...styles,
            color: data.value === "" ? "transparent" : styles.color,
            minHeight: 30,
        }),
    };

    // if(topic=="Energy") debugger

    return (
        <div className={styles.sectionWrapper}>
            {selectedRegion && sections && sections[topic] && (
                <div id={topic.toLowerCase()} className={styles.topic}>
                    <div className={styles.headerRow + " first"}>
                        <h3>
                            {topic}
                            <hr className={styles.line} data-topic={topic.toLowerCase()} />
                        </h3>
                        {comparableRegions && (
                            <div className={styles.comparisonWrapper}>
                                <p className={styles.selectLabel} data-topic={topic.toLowerCase()}>
                                    Compare with
                                </p>
                                <div className={styles.selectWrapper}>
                                    <label
                                        style={styles.label}
                                        id={"aria-label-" + topic}
                                        htmlFor={topic + "-comparison-input"}
                                    >
                                        {"Select a " + topic + " region for comparison"}
                                    </label>
                                    <Select
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: provided => ({
                                                ...provided,
                                                zIndex: 9999,
                                                fontFamily: "Montserrat, sans-serif",
                                                color: "red",
                                            }),
                                            menu: (provided, state) => ({
                                                ...provided,
                                                fontFamily: "Montserrat, sans-serif",
                                                color: "red",
                                            }),
                                        }}
                                        aria-labelledby={"aria-label-" + topic}
                                        inputId={topic + "-comparison-input"}
                                        defaultValue={null}
                                        options={comparableRegions}
                                        onChange={handleComparedRegionSelect}
                                        placeholder={"Select another " + regionType}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {meta && (
                        <div className={styles.headerRow}>
                            <div>
                                Data from {regions.length} {regionType}s (last updated {meta.lastUpdated}).
                            </div>
                            {/* <a href={meta.dashboardURL}>Find out more about {topic} in {selectedRegion[sections[topic].location_key]} ></a> */}
                            <a href={meta.dashboardURL}>Find out more about {topic.toLowerCase()} ></a>
                        </div>
                    )}

                    <div>
                        <div className={styles.factoids + " " + styles.general}>
                            {sections[topic]["factoids"] &&
                                sections[topic]["factoids"]
                                    .filter(d => d.type === "general")
                                    .map(f => {
                                        const valueType =
                                            !f.name.includes("status") && selectedRegion[f.column]
                                                ? f.unit
                                                : "categorical";

                                        const caveat = CAVEATS.indexOf(f.name) !== -1 ? true : false;

                                        return (
                                            <React.Fragment key={f.name}>
                                                <div className={styles.factoid + " " + styles.general}>
                                                    <img
                                                        src={`${process.env.REACT_APP_PUBLIC_URL}/img/${f.icon}`}
                                                        alt={f.name + " icon"}
                                                        width="40"
                                                        height="40"
                                                    />
                                                    <div className={styles.name}>
                                                        {f.name}
                                                        {caveat ? "*" : ""}
                                                    </div>
                                                    <div
                                                        className={styles.regions}
                                                        data-comparison={
                                                            comparison && comparedRegion ? "true" : "false"
                                                        }
                                                    >
                                                        <div className={styles.region}>
                                                            <div
                                                                className={styles.value}
                                                                data-data-available={
                                                                    ((["number", "percentage", "currency"].indexOf(
                                                                        f.unit
                                                                    ) !== -1 &&
                                                                        typeof selectedRegion[f.column] === "number") ||
                                                                        (f.unit == "categorical" &&
                                                                            typeof selectedRegion[f.column] ===
                                                                                "string")) &&
                                                                    !selectedRegion[f.column]
                                                                        .toString()
                                                                        .includes("#N/A")
                                                                        ? "true"
                                                                        : "false"
                                                                }
                                                                data-value-type={valueType}
                                                                data-topic={topic.toLowerCase()}
                                                            >
                                                                <TextTransition
                                                                    text={
                                                                        ((["number", "percentage", "currency"].indexOf(
                                                                            f.unit
                                                                        ) !== -1 &&
                                                                            typeof selectedRegion[f.column] ===
                                                                                "number") ||
                                                                            (f.unit == "categorical" &&
                                                                                typeof selectedRegion[f.column] ===
                                                                                    "string")) &&
                                                                        !selectedRegion[f.column]
                                                                            .toString()
                                                                            .includes("#N/A")
                                                                            ? humanizeNumber(
                                                                                  selectedRegion[f.column],
                                                                                  f.unit,
                                                                                  true
                                                                              )
                                                                            : "Data not available"
                                                                    }
                                                                    springConfig={presets.gentle}
                                                                    style={{
                                                                        textAlign: "center",
                                                                        width: "100%",
                                                                        whiteSpace: "pre-wrap",
                                                                    }}
                                                                    className={styles.animatedText}
                                                                />
                                                            </div>
                                                            {comparison && comparedRegion && (
                                                                <div
                                                                    className={
                                                                        styles.regionName + " " + styles.selected
                                                                    }
                                                                >
                                                                    <TextTransition
                                                                        text={
                                                                            selectedRegion[sections[topic].location_key]
                                                                                ? selectedRegion[
                                                                                      sections[topic].location_key
                                                                                  ]
                                                                                : "Data not available"
                                                                        }
                                                                        springConfig={presets.gentle}
                                                                        style={{
                                                                            textAlign: "center",
                                                                            width: "100%",
                                                                        }}
                                                                        className={styles.animatedText}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        {comparison && comparedRegion && (
                                                            <div className={styles.region}>
                                                                <div
                                                                    className={styles.value}
                                                                    data-data-available={
                                                                        ((["number", "percentage", "currency"].indexOf(
                                                                            f.unit
                                                                        ) !== -1 &&
                                                                            typeof comparedRegion[f.column] ===
                                                                                "number") ||
                                                                            (f.unit == "categorical" &&
                                                                                typeof comparedRegion[f.column] ===
                                                                                    "string")) &&
                                                                        !comparedRegion[f.column]
                                                                            .toString()
                                                                            .includes("#N/A")
                                                                            ? "true"
                                                                            : "false"
                                                                    }
                                                                    data-value-type={valueType}
                                                                    data-topic={topic.toLowerCase()}
                                                                >
                                                                    <TextTransition
                                                                        text={
                                                                            (([
                                                                                "number",
                                                                                "percentage",
                                                                                "currency",
                                                                            ].indexOf(f.unit) !== -1 &&
                                                                                typeof comparedRegion[f.column] ===
                                                                                    "number") ||
                                                                                (f.unit == "categorical" &&
                                                                                    typeof comparedRegion[f.column] ===
                                                                                        "string")) &&
                                                                            !comparedRegion[f.column]
                                                                                .toString()
                                                                                .includes("#N/A")
                                                                                ? humanizeNumber(
                                                                                      comparedRegion[f.column],
                                                                                      f.unit,
                                                                                      true
                                                                                  )
                                                                                : "Data not available"
                                                                        }
                                                                        springConfig={presets.gentle}
                                                                        style={{
                                                                            textAlign: "center",
                                                                            width: "100%",
                                                                        }}
                                                                        className={styles.animatedText}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={
                                                                        styles.regionName + " " + styles.comparison
                                                                    }
                                                                    data-topic={topic.toLowerCase()}
                                                                >
                                                                    <TextTransition
                                                                        text={
                                                                            comparedRegion[sections[topic].location_key]
                                                                                ? comparedRegion[
                                                                                      sections[topic].location_key
                                                                                  ]
                                                                                : "Data not available"
                                                                        }
                                                                        springConfig={presets.gentle}
                                                                        style={{
                                                                            textAlign: "center",
                                                                            width: "100%",
                                                                        }}
                                                                        className={styles.animatedText}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                        </div>
                        {sections[topic]["factoids"].filter(d => d.type === "financial").length > 0 && (
                            <h4
                                style={{
                                    margin: "50px 0 20px",
                                }}
                            >
                                Financial results
                            </h4>
                        )}
                        <div className={styles.factoids + " " + styles.financial}>
                            {sections[topic]["factoids"]
                                .filter(d => d.type === "financial")
                                .map(f => {
                                    const unit = !f.name.includes("ratio") ? "currency" : "percentage";
                                    const caveat = CAVEATS.indexOf(f.name) !== -1 ? true : false;

                                    return (
                                        <React.Fragment key={f.name}>
                                            <div className={styles.factoid + " " + styles.financial}>
                                                <div className={styles.name}>
                                                    {f.name}
                                                    {caveat ? "*" : ""}
                                                </div>
                                                <div className={styles.regions}>
                                                    <div className={styles.region + " " + styles.selected}>
                                                        <div
                                                            className={styles.value}
                                                            data-data-available={
                                                                ((["number", "percentage", "currency"].indexOf(unit) !==
                                                                    -1 &&
                                                                    typeof selectedRegion[f.column] === "number") ||
                                                                    (unit == "categorical" &&
                                                                        typeof selectedRegion[f.column] ===
                                                                            "string")) &&
                                                                !selectedRegion[f.column].toString().includes("#N/A")
                                                                    ? "true"
                                                                    : "false"
                                                            }
                                                            data-topic={topic.toLowerCase()}
                                                        >
                                                            <TextTransition
                                                                text={
                                                                    ((["number", "percentage", "currency"].indexOf(
                                                                        unit
                                                                    ) !== -1 &&
                                                                        typeof selectedRegion[f.column] === "number") ||
                                                                        (unit == "categorical" &&
                                                                            typeof selectedRegion[f.column] ===
                                                                                "string")) &&
                                                                    !selectedRegion[f.column]
                                                                        .toString()
                                                                        .includes("#N/A")
                                                                        ? humanizeNumber(selectedRegion[f.column], unit)
                                                                        : "Data not available"
                                                                }
                                                                springConfig={presets.gentle}
                                                                style={{
                                                                    textAlign: "center",
                                                                    width: "100%",
                                                                }}
                                                                className={styles.animatedText}
                                                            />
                                                        </div>
                                                        {comparison && comparedRegion && (
                                                            <div className={styles.regionName + " " + styles.selected}>
                                                                <TextTransition
                                                                    text={
                                                                        selectedRegion[sections[topic].location_key]
                                                                            ? selectedRegion[
                                                                                  sections[topic].location_key
                                                                              ]
                                                                            : "Data not available"
                                                                    }
                                                                    springConfig={presets.gentle}
                                                                    style={{
                                                                        textAlign: "center",
                                                                        width: "100%",
                                                                    }}
                                                                    className={styles.animatedText}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {comparison && comparedRegion && (
                                                        <div className={styles.region + " " + styles.comparison}>
                                                            <div
                                                                className={styles.value}
                                                                data-data-available={
                                                                    ((["number", "percentage", "currency"].indexOf(
                                                                        unit
                                                                    ) !== -1 &&
                                                                        typeof comparedRegion[f.column] === "number") ||
                                                                        (unit == "categorical" &&
                                                                            typeof comparedRegion[f.column] ===
                                                                                "string")) &&
                                                                    !comparedRegion[f.column]
                                                                        .toString()
                                                                        .includes("#N/A")
                                                                        ? "true"
                                                                        : "false"
                                                                }
                                                                data-topic={topic.toLowerCase()}
                                                            >
                                                                <TextTransition
                                                                    text={
                                                                        ((["number", "percentage", "currency"].indexOf(
                                                                            unit
                                                                        ) !== -1 &&
                                                                            typeof comparedRegion[f.column] ===
                                                                                "number") ||
                                                                            (unit == "categorical" &&
                                                                                typeof comparedRegion[f.column] ===
                                                                                    "string")) &&
                                                                        !comparedRegion[f.column]
                                                                            .toString()
                                                                            .includes("#N/A")
                                                                            ? humanizeNumber(
                                                                                  comparedRegion[f.column],
                                                                                  unit
                                                                              )
                                                                            : "Data not available"
                                                                    }
                                                                    springConfig={presets.gentle}
                                                                    style={{
                                                                        textAlign: "center",
                                                                        width: "100%",
                                                                    }}
                                                                    className={styles.animatedText}
                                                                />
                                                            </div>
                                                            <div
                                                                className={styles.regionName + " " + styles.comparison}
                                                                data-topic={topic.toLowerCase()}
                                                            >
                                                                <TextTransition
                                                                    text={
                                                                        comparedRegion[sections[topic].location_key]
                                                                            ? comparedRegion[
                                                                                  sections[topic].location_key
                                                                              ]
                                                                            : "Data not available"
                                                                    }
                                                                    springConfig={presets.gentle}
                                                                    style={{
                                                                        textAlign: "center",
                                                                        width: "100%",
                                                                    }}
                                                                    className={styles.animatedText}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                    </div>
                    {meta.updateNotes && <div className={styles.footnote}>*{meta.updateNotes}</div>}
                </div>
            )}
        </div>
    );
}
