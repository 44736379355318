export const roundNumber = (num, omitDecimals = false) => {
	const isNegative = num < 0;
	if (isNegative) {
	  num = Math.abs(num);
	}
	let returnNum = num.toFixed(1);
	if (num >= 100000000000) {
	  returnNum = (num / 1000000000).toFixed(0) + 'B';
	} else if (num >= 1000000000) {
	  returnNum = (num / 1000000000).toFixed(1) + 'B';
	} else if (num >= 100000000) {
	  returnNum = (num / 1000000).toFixed(0) + 'M';
	} else if (num >= 1000000) {
	  returnNum = (num / 1000000).toFixed(1) + 'M';
	} else if (num >= 100000) {
	  returnNum = (num / 1000).toFixed(0) + 'K';
	} else if (num >= 1000) {
	  returnNum = (num / 1000).toFixed(1) + 'K';
	} else {
	  returnNum = omitDecimals ? num.toFixed(0) : num.toFixed(1);
	}
  
  
	return isNegative ? '-' + returnNum : returnNum;
  };
  
  const numberWithCommas = x => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  
  export const humanizeNumber = (num, unit, omitDecimals) => {
  
	if (num === null || num === undefined) return null;
	if (num === '-') return num;
	// todo, should this be here? Its related to the new "↓" trends
	if (typeof num === 'string') return num.replace(';', ' \n ');
	switch (unit) {
	  case 'currency':
		return '$' + roundNumber(num);
	  case 'number':
		return roundNumber(num, omitDecimals);
	  case 'month':
		return roundNumber(num) + ' months';
	  case 'years':
		return roundNumber(num) + ' years';
	  case 'percentage':
		return roundNumber(num * 100) + '%';
	  case 'kilometer':
		return numberWithCommas(num) + 'km';
	  case 'kilometerSquare':
		return numberWithCommas(num) + 'km²';
	  default:
		return roundNumber(num);
	}
  };
  
  export const colorMatcher = color => {
	switch (color) {
	  case 'purple':
		return '#28336E';
	  case 'orange':
		return '#E0603A';
	  case 'green':
		return '#67b85c';
	  case 'brown':
		return '#9A8273';
	  case 'grey':
		return '#443F3E';
	  default:
		return '#000000';
	}
  };
  
  export const oneDecimal = (number) => {
	if (isNaN(number)) {
	  return "-";
	} else {
	  return Math.round(number * 10) / 10;
	}
  }
  
  export const getCategoryUnit = category => {
	switch (category) {
	  case 'operatingPerformance':
	  case 'operatingSurplusRatio':
	  case 'averageOsr':
	  case 'assetSustainabilityRatio':
	  case 'averageAsr':
	  case 'netFinancialLiabilities':
	  case 'ownSourceOperatingRevenue':
	  case 'ratesAndAnnualChargesOutstandingPercentage':
	  case 'retainedSurplusExpenditure':
		return 'percentage';
  
	  case 'unrestrictedCurrentRatio':
	  case 'debtServiceCoverRatio':
	  case 'cashAvailableDays':
	  case 'frontlineStaff':
	  case 'nonFrontLineStaff':
	  case 'staffFtEs':
	  case 'year2019StudentNumbersEftsl':
	  case 'year2019StudentStaffRatioEftslFte':
	  case 'numberOfSchoolsCampusesInQld':
	  case 'numberOfSchoolsCampusesOutsideQld':
	  case 'qwau':
	  case 'unisDomesticStudents':
	  case 'unisInternationalStudents':
		return 'number';
  
	  case 'cashExpenseCoverRatio':
	  case 'averageRemainingUsefulLifeOfInfrastructureAssets':
		return 'years';
	  default:
		return 'currency';
	}
  };
  
  export const dataCategories = [
	{
	  id: 'revenue',
	  label: 'Revenue',
	  color: 'purple',
	  unit: 'currency',
	},
	{ id: 'expenses', label: 'Expenses', color: 'orange', unit: 'currency' },
	{
	  id: 'other',
	  label: 'Other comprehensive income',
	  color: 'grey',
	  text: '',
	  unit: 'currency',
	},
	{ id: 'assets', label: 'Assets', color: 'green', unit: 'currency' },
	{ id: 'liabilities', label: 'Liabilities', color: 'brown', unit: 'currency' },
	{
	  id: 'otherInformation',
	  label: 'Other information',
	  color: 'grey',
	  text: '',
	  unit: 'percentage',
	},
  ];
  
  export const camelize = str => {
	return str
	  .replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
		return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
	  })
	  .replace(/\s+/g, '');
  };
  