import axios from "axios";
import React from "react";
import { lgasGeoJson, hhsGeoJson, qaoDistrictGeoJson, sa4GeoJson, seQldDistrictsGeoJson } from "../config";

const dataURLs = {
    lgaGeoURL: `${process.env.REACT_APP_PUBLIC_URL}/${lgasGeoJson}`,
    hhsGeoURL: `${process.env.REACT_APP_PUBLIC_URL}/${hhsGeoJson}`,
    qaoDistrictGeoURL: `${process.env.REACT_APP_PUBLIC_URL}/${qaoDistrictGeoJson}`,
    sa4GeoURL: `${process.env.REACT_APP_PUBLIC_URL}/${sa4GeoJson}`,
    seQldDistrictsGeoURL: `${process.env.REACT_APP_PUBLIC_URL}/${seQldDistrictsGeoJson}`,
};

const useGeoData = () => {
    const [qldCouncilsGeo, setQldCouncilsGeo] = React.useState(null);
    const [hhsGeo, setHhsGeo] = React.useState(null);
    const [qaoDistrictGeo, setQaoDistrictGeo] = React.useState(null);
    const [sa4Geo, setSa4Geo] = React.useState(null);
    const [seQldDistrictsGeo, setSeQldDistrictsGeo] = React.useState(null);

    React.useEffect(() => {
        Promise.all([
            axios(dataURLs.lgaGeoURL)
                .then(res => res.data)
                .then(setQldCouncilsGeo),

            axios(dataURLs.hhsGeoURL)
                .then(res => res.data)
                .then(setHhsGeo),

            axios(dataURLs.qaoDistrictGeoURL)
                .then(res => {
                    return res.data;
                })
                .then(setQaoDistrictGeo),

            axios(dataURLs.sa4GeoURL)
                .then(res => res.data)
                .then(setSa4Geo),

            axios(dataURLs.seQldDistrictsGeoURL)
                .then(res => res.data)
                .then(setSeQldDistrictsGeo),
        ]);
    }, []);

    return {
        qldCouncilsGeo,
        hhsGeo,
        qaoDistrictGeo,
        sa4Geo,
        seQldDistrictsGeo,
    };
};

export default useGeoData;
