import React from "react";
import styles from "./about.module.scss";
import TextTransition, { presets } from "react-text-transition";
import { humanizeNumber } from "../../helpers/formatters";

export default function About(props) {
    const { locationLga, lgaStats, lgaStatsMeta } = props;

    const [selectedLga, setSelectedLga] = React.useState(false);
    const [allLgaStats, setAllLgaStats] = React.useState(false);

    React.useEffect(() => {
        if (lgaStats) {
            setAllLgaStats(lgaStats);
        }
    }, [lgaStats]);

    React.useEffect(() => {
        if (locationLga) {
            setSelectedLga(lgaStats.find(lga => lga && lga.short_name === locationLga));
        }
    }, [locationLga, lgaStats]);

    const uniqueSources = lgaStatsMeta
        .map(s => {
            return s.sourceName;
        })
        .filter((x, i, a) => a.indexOf(x) === i)
        .filter((x, i, a) => x !== "");

    const getSourceIndex = metric => {
        const source = lgaStatsMeta.find(m => {
            return m.metric === metric;
        });
        const sourceIndex = uniqueSources.indexOf(source.sourceName);
        return !isNaN(sourceIndex) && sourceIndex >= 0 ? sourceIndex + 1 : "";
    };

    return (
        <div className={styles.aboutWrapper}>
            {selectedLga && (
                <div>
                    <h2 className={styles.aboutHeading}>About {selectedLga.name}</h2>
                    <div className={styles.row}>
                        <div className={styles.statItem}>
                            <div className={styles.icon} data-stat="population"></div>
                            <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}/img/population.svg`}
                                alt="population icon"
                                width="35"
                                height="46"
                            />
                            <div className={styles.content}>
                                <div
                                    className={styles.value}
                                    data-data-available={selectedLga.population ? "true" : "false"}
                                >
                                    <TextTransition
                                        text={
                                            selectedLga.population
                                                ? humanizeNumber(selectedLga.population, "number")
                                                : "Data not available"
                                        }
                                        springConfig={presets.gentle}
                                    />
                                </div>
                                <div className={styles.name}>
                                    people<sup>{getSourceIndex("population")}</sup>
                                </div>
                            </div>
                        </div>
                        <div className={styles.statItem}>
                            <div className={styles.icon} data-stat="average_age"></div>
                            <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}/img/average_age.svg`}
                                alt="average age icon"
                                width="35"
                                height="46"
                            />
                            <div className={styles.content}>
                                <div
                                    className={styles.value}
                                    data-data-available={selectedLga.average_age ? "true" : "false"}
                                >
                                    <TextTransition
                                        text={
                                            selectedLga.average_age
                                                ? humanizeNumber(selectedLga.average_age, "number")
                                                : "Data not available"
                                        }
                                        springConfig={presets.gentle}
                                    />
                                </div>
                                <div className={styles.name}>
                                    average age<sup>{getSourceIndex("average_age")}</sup>
                                </div>
                            </div>
                        </div>
                        <div className={styles.statItem}>
                            <div className={styles.icon} data-stat="ageing_profiles"></div>
                            <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}/img/ageing_profiles.svg`}
                                alt="ageing profiles icon"
                                width="35"
                                height="46"
                            />
                            <div className={styles.content}>
                                <div
                                    className={styles.value}
                                    data-data-available={selectedLga.ageing_profiles ? "true" : "false"}
                                >
                                    <TextTransition
                                        text={
                                            selectedLga.ageing_profiles
                                                ? humanizeNumber(selectedLga.ageing_profiles, "percentage")
                                                : "Data not available"
                                        }
                                        springConfig={presets.gentle}
                                    />
                                </div>
                                <div className={styles.name}>
                                    Percentage population 65+ in 2041<sup>{getSourceIndex("ageing_profiles")}</sup>
                                </div>
                            </div>
                        </div>
                        <div className={styles.statItem}>
                            <div className={styles.icon} data-stat="population_growth"></div>
                            <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}/img/population_growth.svg`}
                                alt="population growth icon"
                                width="35"
                                height="46"
                            />
                            <div className={styles.content}>
                                <div
                                    className={styles.value}
                                    data-data-available={selectedLga.population_growth ? "true" : "false"}
                                >
                                    <TextTransition
                                        text={
                                            selectedLga.population_growth
                                                ? humanizeNumber(selectedLga.population_growth, "percentage")
                                                : "Data not available"
                                        }
                                        springConfig={presets.gentle}
                                    />
                                </div>
                                <div className={styles.name}>
                                    population growth to 2041<sup>{getSourceIndex("population_growth")}</sup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.statItem}>
                            <div className={styles.icon} data-stat="atsi"></div>
                            <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}/img/atsi.svg`}
                                alt="atsi icon"
                                width="35"
                                height="46"
                            />
                            <div className={styles.content}>
                                <div className={styles.value} data-data-available={selectedLga.atsi ? "true" : "false"}>
                                    <TextTransition
                                        text={
                                            selectedLga.atsi
                                                ? humanizeNumber(selectedLga.atsi, "percentage")
                                                : "Data not available"
                                        }
                                        springConfig={presets.gentle}
                                    />
                                </div>
                                <div className={styles.name}>
                                    First Nations Australians<sup>{getSourceIndex("atsi")}</sup>
                                </div>
                            </div>
                        </div>
                        <div className={styles.statItem}>
                            <div className={styles.icon} data-stat="median_income"></div>
                            <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}/img/median_income.svg`}
                                alt="median income icon"
                                width="35"
                                height="46"
                            />
                            <div className={styles.content}>
                                <div
                                    className={styles.value}
                                    data-data-available={selectedLga.median_income ? "true" : "false"}
                                >
                                    <TextTransition
                                        text={
                                            selectedLga.median_income
                                                ? humanizeNumber(selectedLga.median_income, "currency")
                                                : "Data not available"
                                        }
                                        springConfig={presets.gentle}
                                    />
                                </div>
                                <div className={styles.name}>
                                    median income<sup>{getSourceIndex("median_income")}</sup>
                                </div>
                            </div>
                        </div>
                        <div className={styles.statItem}>
                            <div className={styles.icon} data-stat="unemployment"></div>
                            <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}/img/unemployment.svg`}
                                alt="unemployment icon"
                                width="35"
                                height="46"
                            />
                            <div className={styles.content}>
                                <div
                                    className={styles.value}
                                    data-data-available={selectedLga.unemployment ? "true" : "false"}
                                >
                                    <TextTransition
                                        text={
                                            selectedLga.unemployment
                                                ? humanizeNumber(selectedLga.unemployment, "percentage")
                                                : "Data not available"
                                        }
                                        springConfig={presets.gentle}
                                    />
                                </div>
                                <div className={styles.name}>
                                    unemployment<sup>{getSourceIndex("unemployment")}</sup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.statItem + " " + styles.wide}>
                            <div className={styles.icon} data-stat="primary_industry"></div>
                            <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}/img/primary_industry.svg`}
                                alt="primary industry icon"
                                width="35"
                                height="46"
                            />
                            <div className={styles.content}>
                                <div className={styles.value + " " + styles.small}>
                                    <TextTransition
                                        text={
                                            selectedLga.primary_industry
                                                ? selectedLga.primary_industry
                                                : "Data not available"
                                        }
                                        springConfig={presets.gentle}
                                    />
                                </div>
                                <div className={styles.name}>
                                    primary industries<sup>{getSourceIndex("primary_industry")}</sup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.legend}>
                        <span> This demographic data was sourced from the</span>
                        {uniqueSources &&
                            uniqueSources.map(s => {
                                const sourceObj = lgaStatsMeta.find(m => {
                                    return m.sourceName === s;
                                });
                                const sourceInd = uniqueSources.indexOf(s) + 1;
                                return (
                                    <React.Fragment key={s}>
                                        <a
                                            className={styles.externalLink}
                                            href={sourceObj.sourceURL}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <sup>{sourceInd}</sup>
                                            <span>{s}</span>
                                        </a>
                                    </React.Fragment>
                                );
                            })}
                    </div>
                </div>
            )}
        </div>
    );
}
