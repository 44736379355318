import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Async from "react-select/async";
import styles from "./geo-search-input.module.scss";
import Inside from "turf-inside";
import { preset_4730, preset_53_albert, preset_townsville } from "../../config/constants";

// QLD coordinates
const bbox = [137.994324998, -29.1778977, 153.552171248, -9.14217597699997];
const token = "pk.eyJ1Ijoic21hbGxtdWx0aXBsZXMiLCJhIjoiRk4xSUp6OCJ9.GilBdBaV0oKMZgBwBqRMWA";
const baseUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/{text}.json?country=au&bbox=${bbox}&access_token=${token}`;
const getUrl = text => {
    return baseUrl.replace("{text}", text.replace(/\s/g, "+"));
};

const Option = props => {
    const { innerProps, getStyles, data } = props;
    return (
        <div
            style={{
                ...getStyles("option", props),
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
                cursor: "pointer",
            }}
            className={styles.option}
            {...innerProps}
        >
            <span className={styles.optionCouncil}>
                {data.place_name}
                <img
                    style={{
                        height: 15,
                        width: 10,
                        marginLeft: 5,
                    }}
                    alt="Right chevron"
                    src={process.env.REACT_APP_PUBLIC_URL + "/img/chevron-right-icon.svg"}
                />
            </span>
        </div>
    );
};

class GeoSearchInput extends Component {
    constructor(props) {
        super(props);

        const { sa4s, hhs, councils } = props;
        // const [selectedOption, setSelectedOption] = React.useState("");

        this.state = {
            selectedCouncil: null,
            sa4s: sa4s,
            hhs: hhs,
            councils: councils,
        };
        this.getOptions = this.getOptions.bind(this);
        // this.handleLocationChange = this.props.handleLocationChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handlePresetSelect = this.handlePresetSelect.bind(this);
    }

    handleOnChange(d) {
        const { onLocationSelect } = this.props;
        if (!d) {
            d = {
                target: this.selectRef,
                value: "",
            };
        }
        // onChange(d);
        if (!d) return;
        this.setState({ selectedLocation: d });
        onLocationSelect(d);
    }

    handlePresetSelect(d) {
        const { onLocationSelect } = this.props;
        this.setState({ selectedLocation: d });
        onLocationSelect(d);
    }

    validateLocation(d) {
        const { sa4s, hhs, councils } = this.props;
        if (d && d.geometry) {
            const inASa4 = sa4s.features.find(sa4 => {
                return sa4.geometry ? Inside(d, sa4) : false;
            });

            const inAHhs = hhs.features.find(region => {
                return region.geometry ? Inside(d, region) : false;
            });

            const inACouncil = councils.features.find(council => {
                return council.geometry ? Inside(d, council) : false;
            });

            return inASa4 && inAHhs && inACouncil;
        }
    }

    getOptions(text) {
        if (!text || text.length < 2) {
            return Promise.resolve([]);
        }
        const url = getUrl(text);
        return fetch(url)
            .then(res => res.json())
            .then(data => {
                const options = data.features.filter(d => {
                    return d.context.find(d => d.short_code === "AU-QLD") && this.validateLocation(d);
                });
                return options;
            });
    }

    filterOptions(options) {
        return options;
    }

    renderInput() {
        const { selectedLocation } = this.state;
        const customStyles = {
            input: (provided, state) => ({
                ...provided,
                textIndent: "2px",
            }),
        };

        return (
            <label className={styles.searchLabel}>
                <span className={styles.searchLabelText}>Search by address</span>
                <Async
                    ref={ref => {
                        this.selectRef = ref;
                    }}
                    isClearable
                    components={{ Option }}
                    loadOptions={this.getOptions}
                    value={{
                        label: selectedLocation ? selectedLocation.place_name : "Start here",
                        value: selectedLocation ? selectedLocation : null,
                    }}
                    onChange={this.handleOnChange}
                    isLoading={false}
                    defaultOptions={[]}
                    placeholder={"Search by address"}
                    filterOptions={this.filterOptions}
                    styles={customStyles}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: "#28336E",
                        },
                    })}
                />
            </label>
        );
    }

    render() {
        return (
            <div>
                <div className={styles.geoSearchInputWrapper}>
                    {this.renderInput()}
                    <div className={styles.geoSearchInputImageWrapper}>
                        <img
                            className={styles.geoSearchInputImage}
                            src={`${process.env.REACT_APP_PUBLIC_URL}/img/search-icon.svg`}
                            alt="search icon"
                        />
                    </div>
                </div>
                <p className={styles.presets}>
                    Try&nbsp;
                    <span className={styles.preset} onClick={() => this.handlePresetSelect(preset_53_albert)}>
                        53 Albert St, Brisbane City QLD 4000
                    </span>{" "}
                    or&nbsp;
                    <span className={styles.preset} onClick={() => this.handlePresetSelect(preset_townsville)}>
                        Townsville
                    </span>{" "}
                    or&nbsp;
                    <span className={styles.preset} onClick={() => this.handlePresetSelect(preset_4730)}>
                        4730
                    </span>
                </p>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

GeoSearchInput.propTypes = {
    onCouncilSelect: PropTypes.func,
    councilsGeo: PropTypes.object,
    handleLocationChange: PropTypes.func,
};

export default connect(mapStateToProps, null)(GeoSearchInput);
