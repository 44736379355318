import styles from "./App.scss";

import React from "react";
import { Provider } from "react-redux";
import useQueenslandData from "./hooks/use-queensland-data";
import useGeoData from "./hooks/use-geo-data";
import LaunchScreen from "./components/launch-screen";
import GeoSearchInput from "./components/geo-search-input";
import Regions from "./components/regions";
import About from "./components/about";
import Section from "./components/section";
import Navigation from "./components/navigation";
import { useIsMobile } from "./hooks/mobile.js";
import store from "./store";

import { getJsDateFromExcel } from "excel-date-to-js";
import dateFormat from "dateformat";
import Inside from "turf-inside";

function App() {
    const {
        metaData,
        healthData,
        educationData,
        councilsData,
        energyData,
        waterData,
        sportsData,
        infrastructureData,
        lgaData,
        lgaDataMeta,
    } = useQueenslandData();

    const [meta, setMeta] = React.useState([]);
    const [healthRegions, setHealthRegions] = React.useState([]);
    const [educationRegions, setEducationRegions] = React.useState([]);
    const [councils, setCouncils] = React.useState([]);
    const [energyRegions, setEnergyRegions] = React.useState([]);
    const [waterRegions, setWaterRegions] = React.useState([]);
    const [sportsRegions, setSportsRegions] = React.useState([]);
    const [infrastructureRegions, setInfrastructureRegions] = React.useState([]);
    const [lgaStats, setlgaStats] = React.useState([]);
    const [lgaStatsMeta, setlgaStatsMeta] = React.useState([]);

    const { qldCouncilsGeo, hhsGeo, qaoDistrictGeo, sa4Geo } = useGeoData();

    const [qldCouncils, setQldCouncils] = React.useState(null);
    const [hhs, setHhsGeo] = React.useState(null);
    const [qaoDistricts, setQaoDistrictGeo] = React.useState(null);
    const [sa4s, setSa4Geo] = React.useState(null);
    const [geoReady, setGeoReady] = React.useState(false);

    const [locationHhs, setLocationHhs] = React.useState(null);
    const [locationQaoDistrict, setLocationQaoDistrict] = React.useState(null);
    const [locationLga, setLocationLga] = React.useState(null);
    const [locationSa4, setLocationSa4] = React.useState(null);

    const selectLocation = location => {
        // Get council
        const getCouncil = () => {
            if (location && location.geometry) {
                return qldCouncils.features.find(council => {
                    return council.geometry ? Inside(location, council) : false;
                }).properties.LGA_NAME_2016;
            }
        };

        // Get HHS
        const getHhs = () => {
            if (location && location.geometry) {
                // console.log(hhs.features);
                return hhs.features.find(district => {
                    return district.geometry ? Inside(location.geometry, district) : false;
                }).properties.name;
            }
        };

        // Get QAO District
        const getQaoDistrict = () => {
            if (location && location.geometry) {
                return qaoDistricts.features.find(district => {
                    return district.geometry ? Inside(location, district) : false;
                }).properties.name;
            }
        };

        // Get SA4
        const getSa4 = () => {
            if (location && location.geometry) {
                return sa4s.features.find(sa4 => {
                    return sa4.geometry ? Inside(location, sa4) : false;
                }).properties.NAME;
            }
        };

        // Get the associated regions for the location
        const selectedHhs = getHhs();
        if (selectedHhs) setLocationHhs(selectedHhs);

        const selectedQaoDistrict = getQaoDistrict();
        if (selectedQaoDistrict) setLocationQaoDistrict(selectedQaoDistrict);

        const selectedCouncil = getCouncil();
        if (selectedCouncil) setLocationLga(selectedCouncil);

        const selectedSa4 = getSa4();
        if (selectedSa4) setLocationSa4(selectedSa4);
    };

    React.useEffect(() => {
        if (qldCouncilsGeo) {
            setQldCouncils(qldCouncilsGeo);
        }
    }, [qldCouncilsGeo]);

    React.useEffect(() => {
        if (hhsGeo) {
            setHhsGeo(hhsGeo);
        }
    }, [hhsGeo]);

    React.useEffect(() => {
        if (qaoDistrictGeo) {
            setQaoDistrictGeo(qaoDistrictGeo);
        }
    }, [qaoDistrictGeo]);

    React.useEffect(() => {
        if (sa4Geo) {
            setSa4Geo(sa4Geo);
        }
    }, [sa4Geo]);

    React.useMemo(() => {
        if (qldCouncils && hhs && qaoDistricts && sa4s) {
            setGeoReady(true);
        }
    }, [qldCouncils, hhs, qaoDistricts, sa4s]);

    // Topic meta data
    React.useEffect(() => {
        if (metaData) {
            setMeta(
                metaData.map((d, i) => {
                    return {
                        topic: d["Topic"],
                        lastUpdated:
                            typeof d["Last Updated"] === "number"
                                ? dateFormat(getJsDateFromExcel(d["Last Updated"]), "mmm yyyy")
                                : d["Last Updated"],
                        updateNotes: d["Update Notes"],
                        dashboardURL: d["Dashboard URL"],
                    };
                })
            );
        }
    }, [metaData]);

    const isMobile = useIsMobile();

    // Council stats
    React.useEffect(() => {
        if (lgaData) {
            const stats = lgaData.map((d, i) => {
                // if (i !== 0 && d["col0"] !== "region") {
                return {
                    id: i,
                    name: d["region"],
                    short_name: d["region_short_name"],
                    population: d["population"],
                    average_age: d["average_age"],
                    median_income: d["median_income"],
                    primary_industry: d["primary_industry"],
                    population_growth: d["population_growth"],
                    ageing_profiles: d["ageing_profiles"],
                    atsi: d["atsi"],
                    unemployment: d["unemployment"],
                };
                // }
            });
            setlgaStats(stats.filter(n => n));
        }
    }, [lgaData]);

    // Council stats meta
    React.useEffect(() => {
        if (lgaDataMeta) {
            const sources = lgaDataMeta.map((d, i) => {
                return {
                    id: i,
                    metric: d["Metric"],
                    sourceName: d["Source name"],
                    sourceURL: d["Source URL"],
                };
            });
            setlgaStatsMeta(sources.filter(n => n));
        }
    }, [lgaDataMeta]);

    // Health
    React.useEffect(() => {
        if (healthData) {
            const stats = healthData.map((d, i) => {
                // if (i !== 0 && d["col0"] !== "region") {
                return {
                    id: i,
                    region: d["region"],
                    hospitals: d["hospitals"],
                    hospital_staff_fte: d["hospital_staff_fte"],
                    hospitalisations: d["hospitalisations"],
                    financial_revenue: d["financial_revenue"] * 1000,
                    financial_assets: d["financial_assets"] * 1000,
                    financial_expenses: d["financial_expenses"] * 1000,
                    financial_liabilities: d["financial_liabilities"] * 1000,
                    financial_operating_result: d["financial_operating_result"] * 1000,
                };
                // }
                // return {};
            });
            setHealthRegions(stats.filter(n => n));
        }
    }, [healthData]);

    // Eduation
    React.useEffect(() => {
        if (educationData) {
            const stats = educationData.map((d, i) => {
                return {
                    id: i,
                    region: d["region"],
                    schools: d["schools"],
                    universities_tafe_campuses: d["universities_tafe_campuses"],
                    internet_speed_per_student: d["internet_speed_per_student"],
                    school_staff_fte: d["school_staff_fte"],
                    university_tafe_staff_fte: d["university_tafe_staff_fte"],
                    students: d["students"],
                };
            });
            setEducationRegions(stats.filter(n => n));
        }
    }, [educationData]);

    React.useEffect(() => {
        if (councilsData) {
            const stats = councilsData.map((d, i) => {
                // if (i !== 0 && d["col0"] !== "region") {
                return {
                    id: i,
                    region: d["region"],
                    region_short_name: d["region_short_name"],
                    council_staff_fte: d["council_staff_fte"],
                    financial_revenue: d["financial_revenue"] * 1000,
                    financial_assets: d["financial_assets"] * 1000,
                    financial_expenses: d["financial_expenses"] * 1000,
                    financial_liabilities: d["financial_liabilities"] * 1000,
                    financial_operating_surplus_ratio: d["financial_operating_surplus_ratio"],
                };
                // }
                // return {};
            });
            setCouncils(stats.filter(n => n));
        }
    }, [councilsData]);

    // Energy
    React.useEffect(() => {
        if (energyData) {
            const stats = energyData.map((d, i) => {
                // if (i !== 0 && d["col0"] !== "region") {
                return {
                    id: i,
                    region: d["region"],
                    powerstations: d["powerstations"],
                    generation_capacity: d["generation_capacity"],
                    household_rooftop_solar: d["household_rooftop_solar"],
                };
                // }
                // return {};
            });
            setEnergyRegions(stats.filter(n => n));
        }
    }, [energyData]);

    // Water
    React.useEffect(() => {
        if (waterData) {
            const stats = waterData.map((d, i) => {
                if (d["col0"] !== "region") {
                    return {
                        id: i,
                        region: d["region"],
                        region_short_name: d["region_short_name"],
                        dams: d["dams"],
                        drought_status: d["drought_status"],
                    };
                }
                return {};
            });
            setWaterRegions(stats.filter(n => n));
        }
    }, [waterData]);

    // Sports
    React.useEffect(() => {
        if (sportsData) {
            const stats = sportsData.map((d, i) => {
                return {
                    id: i,
                    region: d.region,
                    grants: d.grants,
                };
            });
            setSportsRegions(stats.filter(n => n));
        }
    }, [sportsData]);

    // Infrastructure
    React.useEffect(() => {
        if (infrastructureData) {
            const stats = infrastructureData.map((d, i) => {
                // if (i !== 0 && d["col0"] !== "region") {
                return {
                    id: i,
                    region: d["col0"],
                    spend_on_major_projects: d["col1"],
                };
                // }
                // return {};
            });
            setInfrastructureRegions(stats.filter(n => n));
        }
    }, [infrastructureData]);

    return (
        <Provider store={store}>
            <div
                className="App"
                style={
                    isMobile
                        ? {
                              // mobile styles
                          }
                        : {
                              //   desktop styles
                          }
                }
            >
                <div className="searchWrapper">
                    {geoReady && (
                        <GeoSearchInput
                            onLocationSelect={selectLocation}
                            sa4s={sa4s}
                            hhs={hhs}
                            councils={qldCouncils}
                        />
                    )}
                </div>
                {locationHhs === null && locationSa4 === null && locationLga === null && <LaunchScreen />}
                {locationHhs !== null && locationSa4 !== null && locationLga !== null && (
                    <div>
                        <Regions
                            locationHhs={locationHhs}
                            locationQaoDistrict={locationSa4}
                            locationLga={locationLga}
                        />
                        <About locationLga={locationLga} lgaStats={lgaStats} lgaStatsMeta={lgaStatsMeta} />
                        <Navigation />
                        <Section
                            topic="Health"
                            location={locationHhs}
                            regions={healthRegions}
                            regionType="health region"
                            meta={meta.find(d => d.topic === "Health")}
                        />
                        <Section
                            topic="Education"
                            location={locationSa4}
                            regions={educationRegions}
                            regionType="Queensland region"
                            meta={meta.find(d => d.topic === "Education")}
                        />
                        <Section
                            topic="Councils"
                            location={locationLga}
                            regions={councils}
                            regionType="council"
                            meta={meta.find(d => d.topic === "Councils")}
                        />
                        <Section
                            topic="Energy"
                            location={locationSa4}
                            regions={energyRegions}
                            regionType="Queensland region"
                            meta={meta.find(d => d.topic === "Energy")}
                        />
                        <Section
                            topic="Water"
                            location={locationLga}
                            regions={waterRegions}
                            regionType="Queensland region"
                            meta={meta.find(d => d.topic === "Water")}
                        />
                        <Section
                            topic="Sports"
                            location={locationSa4}
                            regions={sportsRegions}
                            regionType="Queensland region"
                            meta={meta.find(d => d.topic === "Sports")}
                        />
                        {/* <Section
                            topic="Infrastructure"
                            location={locationSa4}
                            regions={infrastructureRegions}
                            regionType="Queensland region"
                            meta={meta.find(d => d.topic === "Infrastructure")}
                        /> */}
                    </div>
                )}
            </div>
        </Provider>
    );
}

export default App;
