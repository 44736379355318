import React from "react";
import styles from "./navigation.module.scss";
import * as Scroll from "react-scroll";

export default function Navigation(props) {

    const Link = Scroll.Link;

    return (
        <div className={styles.navigationWrapper}>
            <ul className={styles.navigation}>
                <li className={styles.section}>
                    <Link to="health" spy={true} smooth={true}>
                        <span className={styles.heading}>Health</span>
                        <button className={styles.button} data-topic="health">
                            Go to health section
                            <svg
                                className={styles.chevron}
                                fill={"white"}
                                width="20"
                                height="18"
                                viewBox="0 0 1792 1792"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
                            </svg>
                        </button>
                    </Link>
                </li>
                <li className={styles.section}>
                    <Link to="education" spy={true} smooth={true}>
                        <span className={styles.heading}>Education</span>
                        <button className={styles.button} data-topic="education">
                            Go to education section
                            <svg
                                className={styles.dataCubeChevron}
                                fill={"white"}
                                width="20"
                                height="18"
                                viewBox="0 0 1792 1792"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
                            </svg>
                        </button>
                    </Link>
                </li>
                <li className={styles.section}>
                    <Link to="councils" spy={true} smooth={true}>
                        <span className={styles.heading}>Councils</span>
                        <button className={styles.button} data-topic="councils">
                            Go to councils section
                            <svg
                                className={styles.dataCubeChevron}
                                fill={"white"}
                                width="20"
                                height="18"
                                viewBox="0 0 1792 1792"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
                            </svg>
                        </button>
                    </Link>
                </li>
                <li className={styles.section}>
                    <Link to="energy" spy={true} smooth={true}>
                        <span className={styles.heading}>Energy</span>
                        <button className={styles.button} data-topic="energy">
                            Go to energy section
                            <svg
                                className={styles.dataCubeChevron}
                                fill={"white"}
                                width="20"
                                height="18"
                                viewBox="0 0 1792 1792"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
                            </svg>
                        </button>
                    </Link>
                </li>
                <li className={styles.section}>
                    <Link to="water" spy={true} smooth={true}>
                        <span className={styles.heading}>Water</span>
                        <button className={styles.button} data-topic="water">
                            Go to water section
                            <svg
                                className={styles.dataCubeChevron}
                                fill={"white"}
                                width="20"
                                height="18"
                                viewBox="0 0 1792 1792"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
                            </svg>
                        </button>
                    </Link>
                </li>
                <li className={styles.section}>
                    <Link to="sports" spy={true} smooth={true}>
                        <span className={styles.heading}>Sports</span>
                        <button className={styles.button} data-topic="sports">
                            Go to sports section
                            <svg
                                className={styles.dataCubeChevron}
                                fill={"white"}
                                width="20"
                                height="18"
                                viewBox="0 0 1792 1792"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
                            </svg>
                        </button>
                    </Link>
                </li>
                {/* <li className={styles.section}>
                    <Link to="infrastructure" spy={true} smooth={true}>
                        <span className={styles.heading} style={{ fontSize: "18px" }}>
                            Infrastructure
                        </span>
                        <button className={styles.button} data-topic="infrastructure">
                            Go to infrastructure section
                            <svg
                                className={styles.dataCubeChevron}
                                fill={"white"}
                                width="20"
                                height="18"
                                viewBox="0 0 1792 1792"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
                            </svg>
                        </button>
                    </Link>
                </li> */}
            </ul>
            {/* {isMobile && (
                <button className={styles.introExpando} onClick={() => handleExpand()}>
                    {expanded ? "Less " : "More "} information about the tool
                    data-topic=
                </button>>
            {/* 
            )}
            {(!isMobile || expanded) && (
                <p className={styles.introExpanded}>
                    The Great Barrier Reef Marine Park Authority uses high quality science and deploys innovative
                    solutions to deliver world-leading management of the Great Barrier Reef. Our management actions must
                    be optimised to ensure they lead to desired future outcomes. This is why our priority science and
                    knowledge needs have a focus on elements that will help improve our management and protection
                    strategies over the long term. These priority needs will form the focus of collaboration
                    opportunities with science and knowledge providers, and are informed by the Great Barrier Reef
                    Outlook Report 2019, the Reef 2050 Plan and emerging needs identified by Authority staff. Here, you
                    can explore the themes and their sub-themes, and search for more detailed project level
                    opportunities.
                </p>
            )}
            <button>Contact our science team for more information</button> */}
        </div>
    );
}
