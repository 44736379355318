import React from "react";
import styles from "./regions.module.scss";
import TextTransition, { presets } from "react-text-transition";

export default function Regions(props) {

	const {locationHhs, locationQaoDistrict, locationLga} = props;
    
    return (
        <div className={styles.regionsWrapper}>
            <h2>Regions</h2>
			<div className={styles.regions}>
				<div className={styles.label}>Based on your address, you belong to:</div>
				<div className={styles.region}>
					<span>
                        <TextTransition
                            text={locationHhs ? locationHhs : ""}
                            springConfig={presets.gentle}
                        />
                    </span>
					<p>Hospital and health service area</p>
				</div>
				<div className={styles.region}>
					<span>
                        <TextTransition
                            text={locationQaoDistrict ? locationQaoDistrict : ""}
                            springConfig={presets.gentle}
                        />
                    </span>
					<p>ABS Queensland regions</p>
				</div>
				<div className={styles.region}>
					<span>
                        <TextTransition
                            text={locationLga ? locationLga : ""}
                            springConfig={presets.gentle}
                        />
                    </span>
					<p>Council/local government area</p>
				</div>
			</div>
        </div>
    );
}
