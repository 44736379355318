export const DATA_SHEET_ID = process.env.REACT_APP_DATA_SHEET || "1YIGEuX5Uta6tJFqtP0FNGRHXpZe817g8s-zTdfbrKRQ"; // dev data
export const SHEET_SERVER_URL = `https://holy-sheet.visualise.today`;

export const NOTES_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=notes`;
export const META_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=meta`;
export const HEALTH_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=health`;
export const EDUCATION_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=education`;
export const COUNCILS_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=councils`;
export const ENERGY_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=energy`;
export const WATER_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=water`;
export const SPORTS_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=sports`;
export const MAJOR_PROJECTS_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=major-projects`;
export const LGA_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=statistics_by_lga_council`;
export const LGA_META_SHEET_URL = `${SHEET_SERVER_URL}/sheet/${DATA_SHEET_ID}?range=statistics_by_lga_council_meta`;

export const STATIC_URL =
    process.env.REACT_APP_IS_DEV === "TRUE" ? "/" : "https://qao-queensland-dashboard.visualise.today/";

export const NOTES_SHEET =
    process.env.REACT_APP_IS_DEV === "TRUE" ? NOTES_SHEET_URL : STATIC_URL + "data/notesData.json";
export const META_SHEET = process.env.REACT_APP_IS_DEV === "TRUE" ? META_SHEET_URL : STATIC_URL + "data/metaData.json";
export const HEALTH_SHEET =
    process.env.REACT_APP_IS_DEV === "TRUE" ? HEALTH_SHEET_URL : STATIC_URL + "data/healthData.json";
export const EDUCATION_SHEET =
    process.env.REACT_APP_IS_DEV === "TRUE" ? EDUCATION_SHEET_URL : STATIC_URL + "data/educationData.json";
export const COUNCILS_SHEET =
    process.env.REACT_APP_IS_DEV === "TRUE" ? COUNCILS_SHEET_URL : STATIC_URL + "data/councilsData.json";
export const ENERGY_SHEET =
    process.env.REACT_APP_IS_DEV === "TRUE" ? ENERGY_SHEET_URL : STATIC_URL + "data/energyData.json";
export const WATER_SHEET =
    process.env.REACT_APP_IS_DEV === "TRUE" ? WATER_SHEET_URL : STATIC_URL + "data/waterData.json";
export const SPORTS_SHEET =
    process.env.REACT_APP_IS_DEV === "TRUE" ? SPORTS_SHEET_URL : STATIC_URL + "data/sportsData.json";
export const INFRASTRUCTURE_SHEET =
    process.env.REACT_APP_IS_DEV === "TRUE" ? MAJOR_PROJECTS_SHEET_URL : STATIC_URL + "data/infrastructureData.json";
export const LGA_SHEET = process.env.REACT_APP_IS_DEV === "TRUE" ? LGA_SHEET_URL : STATIC_URL + "data/lgaData.json";
export const LGA_META_SHEET =
    process.env.REACT_APP_IS_DEV === "TRUE" ? LGA_META_SHEET_URL : STATIC_URL + "data/lgaMetaData.json";

export const CAVEATS = ["Hospital staff FTE", "Council staff FTE", "School staff FTE", "University and TAFE staff FTE"];

export const preset_4730 = {
    id: "postcode.14065165851136730",
    type: "Feature",
    place_type: ["postcode"],
    relevance: 1,
    properties: {},
    text: "4730",
    place_name: "4730, Longreach, Queensland, Australia",
    bbox: [141.78617, -24.869274, 144.662033, -22.496577],
    center: [144.25, -23.44],
    geometry: { type: "Point", coordinates: [144.25, -23.44] },
    context: [
        { id: "locality.5834264560072640", text: "Longreach" },
        { id: "place.8892934899072640", wikidata: "Q1721825", text: "Longreach" },
        { id: "region.19496380243439240", wikidata: "Q36074", short_code: "AU-QLD", text: "Queensland" },
        { id: "country.9968792518346070", wikidata: "Q408", short_code: "au", text: "Australia" },
    ],
};
export const preset_53_albert = {
    id: "address.1602481354010794",
    type: "Feature",
    place_type: ["address"],
    relevance: 0.734127,
    properties: { accuracy: "rooftop" },
    text: "Albert Street",
    place_name: "53 Albert Street, Brisbane City Queensland 4000, Australia",
    center: [153.028163, -27.472213],
    geometry: { type: "Point", coordinates: [153.028163, -27.472213] },
    address: "53",
    context: [
        { id: "postcode.1393430686495970", text: "4000" },
        { id: "locality.6100508531314130", wikidata: "Q978583", text: "Brisbane City" },
        { id: "place.10987281989688270", wikidata: "Q34932", text: "Brisbane" },
        { id: "region.19496380243439240", wikidata: "Q36074", short_code: "AU-QLD", text: "Queensland" },
        { id: "country.9968792518346070", wikidata: "Q408", short_code: "au", text: "Australia" },
    ],
};
export const preset_townsville = {
    id: "poi.661424998444",
    type: "Feature",
    place_type: ["poi"],
    relevance: 1,
    properties: {
        foursquare: "4b8b2149f964a5204c9432e3",
        landmark: true,
        wikidata: "Q7830280",
        address: "100 Angus Smith Dr.",
        category: "hospital, clinic, medical center",
        maki: "hospital",
    },
    text: "Townsville Hospital",
    place_name: "Townsville Hospital, 100 Angus Smith Dr., Townsville, Queensland 4814, Australia",
    center: [146.76212800000002, -19.320318],
    geometry: { coordinates: [146.76212800000002, -19.320318], type: "Point" },
    context: [
        { id: "postcode.2347962769323050", text: "4814" },
        { id: "locality.11343641282614230", text: "Douglas" },
        { id: "place.7657359408576270", wikidata: "Q190021", text: "Townsville" },
        { id: "region.19496380243439240", wikidata: "Q36074", short_code: "AU-QLD", text: "Queensland" },
        { id: "country.9968792518346070", wikidata: "Q408", short_code: "au", text: "Australia" },
    ],
};
